import { Controller } from 'stimulus'
import { Carousel } from "mdb-ui-kit";
import { useIntersection } from 'stimulus-use'

// MDB carousel component
// https://mdbootstrap.com/docs/standard/components/carousel/
// When it is initially hidden or when turbo frame inserts it, we need to
// reinitialize. We are using `.appear` to initialize it only when it becomes
// visible in the viewport. So for all data-mdb-carousel-init add this controller.

//    <div data-mdb-carousel-init data-controller="mdb-carousel-appear">
export default class extends Controller {
  connect() {
    console.log("mdb-carousel-appear connect")
    useIntersection(this)
  }

  appear(entry) {
    console.log("mdb-carousel-appear appear")
    new Carousel(this.element)
  }
}
