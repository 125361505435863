import { Controller } from 'stimulus'
/*
 * Use this to trigger clicks based on anchor hash in url
 * <%= link_to "Add", '#', 'data-toggle': 'modal', 'data-target': '#note-form', id: "add-note-button", "data-controller": "click-hash-id-on-connect" %>
 *
 * <%= link_to 'Add note', admin_member_profile_path(@user.member_profile, anchor: "click-add-note-button") %>
 */
export default class extends Controller {
  connect() {
    console.log('click-hash-id-on-connect')
    let element = this.element
    setTimeout(function() { // we need to wait mdb elements to initialize
      if (window.location.hash == `#click-${element.id}`) {
        element.click()
      }
    }, 0)
  }
}
