import { Controller } from "stimulus";

export default class extends Controller {
  click(event) {
    const tabName = event.target.getAttribute("data-tab");

    const url = new URL(window.location.href);
    url.searchParams.set("tab", tabName);
    const newURL = url.toString();
    window.history.replaceState(null, "", newURL);
  }
}

