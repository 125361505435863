import { Controller } from 'stimulus'
import { Input, Timepicker } from "mdb-ui-kit";

// MDB timepicker component
// https://mdbootstrap.com/docs/standard/forms/timepicker/
// After form submission we need to reinitialize timepicker.
// <div data-mdb-timepicker-init data-controller="mdb-timepicker-appear">
export default class extends Controller {
  connect() {
    new Input(this.element)
    new Timepicker(this.element)
  }
}