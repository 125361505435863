// example from https://stripe.com/docs/payments/quickstart
import { Controller } from 'stimulus'

// used in both connect and handleSubmit
var elements
var stripe

export default class extends Controller {
  static targets = [ "paymentElementHolder", "message" ]

  connect() {
    console.log('stripe#connect')
    stripe = Stripe(this.data.get("key"))
    const plan = this.data.get("plan")
    const csrf = document.querySelector('meta[name="csrf-token"]')?.content
    const paymentElementHolder = this.paymentElementHolderTarget

    fetch("/create_client_secret_elements", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-Token': csrf,
      },
      body: JSON.stringify({ plan: plan }),
    })
      .then((response) => response.json())
      .then((result) => {
        const clientSecret = result.clientSecret
        const appearance = {
          theme: 'stripe',
        }
        elements = stripe.elements({ appearance, clientSecret })

        const paymentElementOptions = {
          layout: "tabs",
        }

        const paymentElement = elements.create("payment", paymentElementOptions)
        paymentElement.mount(paymentElementHolder)

    })
  }

  async handleSubmit(e) {
    console.log("handleSubmit")
    e.preventDefault();
    this.setLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: this.data.get("successReturnUrl"),
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment
    this.showMessage(`${error.message} ${error.code}`)
    this.setLoading(false);
  }
  // ------- UI helpers -------

  showMessage(messageText) {
    const messageContainer = this.messageTarget

    messageContainer.classList.remove("d-none");
    messageContainer.textContent = messageText;

    setTimeout(function () {
      messageContainer.classList.add("d-none");
      messageText.textContent = "";
    }, 4000);
  }

  // Show a spinner on payment submission
  setLoading(isLoading) {
    if (isLoading) {
      // Disable the button and show a spinner
      document.querySelector("#submit").disabled = true;
      document.querySelector("#spinner").classList.remove("d-none");
      document.querySelector("#button-text").classList.add("d-none");
    } else {
      document.querySelector("#submit").disabled = false;
      document.querySelector("#spinner").classList.add("d-none");
      document.querySelector("#button-text").classList.remove("d-none");
    }
  }
}
