import { Controller } from 'stimulus'

export default class extends Controller {
  static values = {
    selector: String,
    selectorAllOthers: String,
    selectorActivatorAllOthers: String,
    action: String
  }

  // When you want to toggle single element use id
  // <div data-controller='actiivate' data-selector='#some-id' data-action='click->activate#toggleHidden'>

  // When you want to select multiple elements use data
  // <div data-controller='actiivate' data-selector='[data-some-selector]' data-action='click->activate#toggleHidden'>
  toggleHidden(event) {
    event.preventDefault()

    document.querySelectorAll(event.currentTarget.dataset.selector).forEach(element => element.classList.toggle('hidden'))
  }

  // If you want to toggle active class for buttons/links you can do it like this
  // <a data-controller='activate' data-activate-selector-activator-all-others-value='[data-activator]' data-activator class="btn active">
  toggleOrShowMultipleAndHideAllOthers(event) {
    event.preventDefault()

    const elements = document.querySelectorAll(this.selectorValue)
    const elementsAllOthers = document.querySelectorAll(this.selectorAllOthersValue)
    const activatorsAllOthers = this.selectorActivatorAllOthersValue && document.querySelectorAll(this.selectorActivatorAllOthersValue)
    const addOrRemoveActiveClass = activatorsAllOthers && Array.from(activatorsAllOthers).some(activator => activator.classList.contains('active'))

    elements.forEach(element => {
      if (this.actionValue === 'show') {
        element.classList.remove("hidden")
      } else {
        element.classList.toggle("hidden")
      }

      if (addOrRemoveActiveClass) {
        this.element.classList.add('active')
      }
    }, this)

    Array.from(elementsAllOthers)
      .filter(elementAllOther => !Array.from(elements).includes(elementAllOther))
      .forEach(elementAllOther => elementAllOther.classList.add("hidden"))

    if (addOrRemoveActiveClass) {
      Array.from(activatorsAllOthers)
        .filter(activator => activator !== this.element, this)
        .forEach(activator => activator.classList.remove('active'))
    }
  }

  scrollHalfwayPoint(event) {
    const halfwayPoint = document.documentElement.scrollHeight;
    window.scrollTo({ top: halfwayPoint, behavior: "smooth" });
  }

  addDNone(e) {
    this.element.classList.add("d-none")
  }
}
