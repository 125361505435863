import { Controller } from "stimulus"

// example usage:
// <%= form_with model: @registration_form, url: registration_path(@registration_form.registration), method: :patch, html: { novalidate: true, "data-controller": "uae-zip-zeroes mdb-validation" } do |f| %>
//    <%= f.select :country, options_for_select(countries, selected: f.object.country), {}, { "data-mdb-select-init": true, "data-mdb-size": "lg", "data-action": "change->uae-zip-zeroes#updateZip", "data-controller": "mdb-select-appear", id: "country", autocomplete: "country" } %>
//    <%= f.text_field :zip, class: "form-control form-control-lg mb-1", autocomplete: "postal-code", "data-uae-zip-zeroes-target": "zip", required: true %>
export default class extends Controller {
  static targets = ["zip"]

  updateZip() {
    console.log("uae-zip-zeroes updateZip")
    const selectedCountry = event.target.value;
    if (selectedCountry == "United Arab Emirates") {
      this.zipTarget.value = "00000"
      this.zipTarget.readOnly = true
      this.zipTarget.classList.add('active', 'bg-white')
    } else {
      if (this.zipTarget.value == "00000") {
        this.zipTarget.value = ""
        this.zipTarget.classList.remove('active', 'bg-white')
      }
      this.zipTarget.readOnly = false
    }
  }
}
