import { Ripple, initMDB } from "mdb-ui-kit";

initMDB({ Ripple });

//we should use 'appear' but since there is about 350 buttons in app it's more practical to use this callback to initialize every button when page is loaded
function initializeRipple() {
    document.querySelectorAll('.btn').forEach((button) => {
        new Ripple(button, {
        rippleColor: 'light'
        });
    });
}

document.addEventListener("turbo:load", initializeRipple)
document.addEventListener("DOMContentLoaded", initializeRipple)

//we call initializeRipple this way to be sure modal is fully loaded,if we call it like above, it will call initializeRipple before modal is loaded, and ripple effect would not work 
document.addEventListener('show.bs.modal', function(event) {initializeRipple()})