import { Controller } from 'stimulus'
import { Dropdown } from "mdb-ui-kit";
import { useIntersection } from 'stimulus-use'

// MDB dropdown component: https://mdbootstrap.com/docs/standard/components/dropdowns/
// When it is initially hidden or when turbo frame inserts it, we need to
// reinitialize. We are using `.update` since hidden components are already
// initialized. So for all data-mdb-dropdown-init add this controller. For example:
//
//    <div data-mdb-dropdown-init data-controller="mdb-dropdown-appear">
export default class extends Controller {
  connect() {
    console.log("mdb-dropdown-appear connect")
    useIntersection(this)
  }

  appear(entry) {
    console.log("mdb-dropdown-appear appear")
    new Dropdown(this.element).update()
  }
}
