// This controller is for forms that do not use default JavaScript validation. It applies MDB styling validation and adds a 'was-validated' class post submit. To disable default validation, add a 'novalidate' attribute to the form.
// https://mdbootstrap.com/docs/standard/forms/validation/
import { Controller } from "stimulus";

export default class extends Controller {
  connect() {
    this.element.addEventListener('submit', (event) => {
      if (!this.element.checkValidity()) {
        event.preventDefault();
        event.stopPropagation();
      }
      this.element.classList.add('was-validated');
    }, false);
  }
}