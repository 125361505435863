import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    console.log('main-bell#initialize')
    this.updateCountersForInterests()
    this.updateTitle()
    this.updateCountersForMobileAppMenu()
  }

  updateCountersForInterests() {
    let unreads = JSON.parse(this.data.get('unreads'))
    for(let [id, count] of unreads) {
      let el = document.getElementById(`counter-for-interest-${id}`)
      if (el) {
        if (count == 0) {
          el.innerHTML = ''
        } else {
          el.innerHTML = `<span class='notification'>${count}</span>`
        }
      }
    }
  }

  updateTitle() {
    const body = document.querySelector('body');
    if (body.classList.contains('mobile-app')) return

    let allCount = JSON.parse(this.data.get('allCount'))
    if (allCount == 0) {
      document.title = document.title.replace(/^\(.*\) /,'')
    } else {
      document.title = `(${allCount}) ${document.title.replace(/^\(.*\) /,'')}`
    }
  }

  updateCountersForMobileAppMenu() {
    const sum_of_unread_messages_count = this.data.get('sumOfUnreadMessagesCount')
    const pending_interests_count = this.data.get('pendingInterestsCount')
    window.dispatchEvent(new CustomEvent("main-bell", {detail: {"sum-of-unread-messages-count": sum_of_unread_messages_count}}))
    window.dispatchEvent(new CustomEvent("main-bell", {detail: {"pending-interests-count": pending_interests_count}}))
  }
}
