
//app/javascript/controllers/mdb-search-appear_controller.js
import { Controller } from 'stimulus'
import { Input, Sidenav, initMDB } from "mdb-ui-kit";

initMDB({ Input, Sidenav });

// this controller would be connected to an input field (likely a search box) 
// and a list of links or items in a side navigation bar, 
// filtering the list based on the user's input.
//   <div data-controller="mdb-search-appear">
//   <!-- Search Input -->
//   <input type="text" data-sidenav-target="searchInputSidenav" placeholder="Search...">
//   <!-- Sidenav Links -->
//   <nav>
//     <ul>
//       <li data-sidenav-link>Home</li>
//       <li data-sidenav-link>About</li>
//       <li data-sidenav-link>Services</li>
//       <li data-sidenav-link>Contact</li>
//       <li data-sidenav-link>Blog</li>
//       <li data-sidenav-link>Careers</li>
//     </ul>
//   </nav>
// </div>
// it will display only links that have text that includes the user's input.

          


export default class extends Controller {

  static targets = ["searchInputSidenav"]
 

  validate() {
    const sidenavOptions = document.querySelectorAll('[data-sidenav-link]');
    const filter = this.searchInputSidenavTarget.value.toLowerCase();
    this.showSidenavOptions(sidenavOptions);
    const valueExist = !!filter.length;
    if (valueExist) {
      sidenavOptions.forEach((el) => {
        const elText = el.textContent.trim().toLowerCase();
        const isIncluded = elText.includes(filter);
        if (!isIncluded) {
          el.classList.add('hidden');
        }
      });
    }
  }

  showSidenavOptions(sidenavOptions) {
    sidenavOptions.forEach((el) => {
      el.classList.remove('hidden');
    });
  }
}

