import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    console.log('messages#initialize')
    this.resetAndScroll()
  }

  // <%= form_with ..., html: { 'data-controller': 'messages', 'data-action': 'turbo:submit-end->messages#resetAndScroll' } do |f| %>
  resetAndScroll() {
    console.log('messages#resetAndScroll')
    this.element.reset()
    let id = this.data.get('containerId')
    $(`${id}`).animate({scrollTop: 100000}, 500);
  }
}
