import { Controller } from 'stimulus'
import { useIntersection, useVisibility } from 'stimulus-use'

export default class extends Controller {
  static targets = [ 'button' ]
  // <li class="send-msg" data-controller='message-chat' data-message-chat-member-profile-id='<%= message.member_profile.id %>' hidden>
  // this is called whenever we load message from initial html or when it is
  // appended with turbo stream
  connect() {
    console.log('message-chat#connect')
    this.showMessageAndAddClassMessageRighted()
    this.scrollDown()
    useIntersection(this)
    useVisibility(this)
  }

  showMessageAndAddClassMessageRighted() {
    if (this.isSender())
      this.element.classList.add('message--righted')

    this.element.hidden = false
  }

  isSender() {
    return this.currentMemberProfileId == this.memberProfileId
  }

  get currentMemberProfileId() {
    return document.querySelector("[name=current-member-profile-id]").content
  }

  get memberProfileId() {
    return this.data.get('memberProfileId')
  }

  get readAt() {
    return this.data.get('readAt')
  }

  set readAt(value) {
    this.element.setAttribute('data-message-chat-read-at', value)
  }

  scrollDown() {
    this.element.parentElement.parentElement.scrollTop += 100
  }

  appear(entry) {
    console.log('appear')
    if (this.isSender()) return
    if (this.readAt != '') return
    if (this.pageNotVisible) return

    console.log(`submitting the form ${this.memberProfileId} ${this.currentMemberProfileId}`)
    this.buttonTarget.click()
    this.readAt = 'updated'
  }

  dissapear(entry) {
    console.log('dissappear')
  }

  visible() {
    console.log('visible')
    this.pageNotVisible = false
  }

  invisible() {
    console.log('invisible')
    this.pageNotVisible = true
  }
}
