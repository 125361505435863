import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import { Turbo } from "@hotwired/turbo-rails"
window.Turbo = Turbo
window.jQuery = $
window.Popper = Popper

import * as mdb from "../../../vendor/mdb-ui-kit-pro-advanced/js/mdb.umd.min.js"
window.mdb = mdb;

Rails.start()
ActiveStorage.start()

// node_modules
import '@fortawesome/fontawesome-free/js/fontawesome'
import '@fortawesome/fontawesome-free/js/solid'
import '@fortawesome/fontawesome-free/js/regular'
import '@fortawesome/fontawesome-free/js/brands'
import '@fortawesome/fontawesome-free/css/all.css'
import "dropzone/dist/min/dropzone.min.css"
import "@flaticon/flaticon-uicons/css/all/all.css"

// our javascript
import 'turbo_load_5'
import 'browser_timezone_cookie'
import 'fcm'
import 'ripple'

// our stylesheet
import "stylesheet5/style5"

import "controllers"

