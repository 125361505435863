import { Controller } from "stimulus"
import Splide from '@splidejs/splide';

export default class extends Controller {
  connect() {
    const splide = new Splide(this.element, {
      type: 'slide',
      perPage: 1,
      pagination: false,
      gap: '1rem',
      drag: false,
    });

    splide.mount();
  }
}