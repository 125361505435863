// app/javascript/controllers/scrollbar_controller.js
import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.adjustPaddingIfScrollbar();
    window.addEventListener('resize', this.adjustPaddingIfScrollbar);
  }

  disconnect() {
    window.removeEventListener('resize', this.adjustPaddingIfScrollbar);
  }

  adjustPaddingIfScrollbar = () => {
    // Check if there is a vertical scrollbar
    const hasVerticalScrollbar = document.documentElement.scrollHeight > window.innerHeight;
    this.element.style.paddingRight = hasVerticalScrollbar ? '' : '15px';
  }
}
