import { Controller } from 'stimulus'

// Example usage on single value select on profile edit:
// <div class="row g-3" data-controller='select-nested-options'>
// <%= f.select :religion, options_for_select(MemberProfile.religions, selected: f.object.religion), {}, { "data-mdb-select-init": true, "data-controller": "mdb-select-appear", 'data-select-nested-options-target': '#community', 'data-action': 'select-nested-options#setOptions', "data-mdb-size": "lg", disabled: f.object.columns_that_reached_the_limit.include?('religion') } %>
// <%= f.select :community, options_for_select(MemberProfile.communities, selected: f.object.community), {}, { "data-mdb-select-init": true, "data-controller": "mdb-select-appear", 'id': 'community', 'data-select-nested-options-empty-label': 'Select Religion First', "data-mdb-validation": true, 'data-select-nested-options-groups': MemberProfile::COMMUNITIES.to_json, "data-mdb-size": "lg", disabled: f.object.columns_that_reached_the_limit.include?('community') } %>

// but also for multiselect on custom filter search
// It is used for: Religion > Community, Country > State
export default class extends Controller {
  initialize() {
    console.log('select-nested-options#initialize')

    let sources = this.element.querySelectorAll('[data-select-nested-options-target]')
    for(const sourceFromInitializer of sources) {
      this.setOptions(null, sourceFromInitializer)
    }
  }

  setOptions(event, sourceFromInitializer = null) {
    console.log('setOptions')
    let sourceElement
    if (event) {
      sourceElement = event.currentTarget
    } else {
      // called from initializer
      sourceElement = sourceFromInitializer
    }
    let targetElement = this.element.querySelector(sourceElement.dataset.selectNestedOptionsTarget)
    if (sourceElement.value == '') {
      let label = targetElement.dataset.selectNestedOptionsEmptyLabel
      targetElement.innerHTML = ""
      this.appendToSelect(targetElement, "", "selected", "disabled", label)
      return
    }
    let groups = JSON.parse(targetElement.dataset.selectNestedOptionsGroups)
    let optionValues = []
    sourceElement.selectedOptions.forEach(optionElement => {
      if (groups[optionElement.value]) {
        optionValues = optionValues.concat(groups[optionElement.value])
      }
    })
    let targetValues = []
    targetElement.selectedOptions.forEach(optionElement => {
      if (optionValues.includes(optionElement.value)) {
        targetValues = targetValues.concat(optionElement.value)
      }
    })
    targetElement.innerHTML = ""
    if (optionValues.length > 0) {
      if (targetValues.length === 0) {
        sourceElement.selectedOptions.forEach(optionElement => this.appendToSelect(targetElement, "", "selected", "disabled",`Select for ${optionElement.value}`))
      }

      targetValues.forEach(value => {
        if (optionValues.includes(value)) {
          this.appendToSelect(targetElement, value, "selected")
        }
      })
      optionValues.forEach(value => {
        if (!targetValues.includes(value)) {
          this.appendToSelect(targetElement, value)
        }
      })
    } else {
      // disable select
      this.appendToSelect(targetElement, "N/A", "selected")
    }
  }

  appendToSelect(select, value, selected = false, disabled = false, text = value) {
    var option = document.createElement("option")
    option.value = value
    option.textContent = text
    option.selected = selected
    option.disabled = disabled

    select.appendChild(option)
  }
}
