import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'link' ]

  connect() {
    const activeLink = this.linkTargets.find(link => link.href === window.location.href)

    if (activeLink) {
      document.querySelector(`#${activeLink.id} i.fi-rr-${activeLink.dataset.iconName}`).classList.add('hidden')
      document.querySelector(`#${activeLink.id} i.fi-sr-${activeLink.dataset.iconName}`).classList.remove('hidden')

      this.linkTargets.filter(link => activeLink !== link).forEach(link => {
        document.querySelector(`#${link.id} i.fi-rr-${link.dataset.iconName}`).classList.remove('hidden')
        document.querySelector(`#${link.id} i.fi-sr-${link.dataset.iconName}`).classList.add('hidden')
      })
    } else {
      this.linkTargets.forEach(link => {
        document.querySelector(`#${link.id} i.fi-rr-${link.dataset.iconName}`).classList.remove('hidden')
        document.querySelector(`#${link.id} i.fi-sr-${link.dataset.iconName}`).classList.add('hidden')
      })
    }
  }
}