import { Controller } from 'stimulus'
import { Input, Datepicker } from "mdb-ui-kit";
import { useIntersection } from 'stimulus-use'

// MDB datepicker component
// https://mdbootstrap.com/docs/standard/forms/datepicker/
// When it is initially hidden or when turbo frame inserts it, we need to
// reinitialize. We are using `.update` since hidden components are already
// initialized. So for all data-mdb-datepicker-init add this controller. For example:
//
//    <div data-mdb-datepicker-init data-controller="mdb-datepicker-appear">
export default class extends Controller {
  connect() {
    console.log("mdb-datepicker-appear connect")
    useIntersection(this)
  }

  appear(entry) {
    console.log("mdb-datepicker-appear appear")
    new Input(this.element)
    new Datepicker(this.element)
  }
}
