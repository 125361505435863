import { Controller } from 'stimulus'
import { Select } from "mdb-ui-kit";
import { useIntersection } from 'stimulus-use'

// MDB select component
// https://mdbootstrap.com/docs/standard/forms/select/
// When it is initially hidden or when turbo frame inserts it, we need to
// reinitialize. We are using `.update` since hidden components are already
// initialized. So for all data-mdb-select-init add this controller. For example:
//
//    <div data-mdb-select-init data-controller="mdb-select-appear">
export default class extends Controller {
  connect() {
    console.log("mdb-select-appear connect")
    useIntersection(this)
  }

  appear(entry) {
    console.log("mdb-select-appear appear")
    new Select(this.element)
  }
}
