import { Controller } from "stimulus"
import consumer from "channels/consumer"

export default class extends Controller {
  static targets = [ "status", "logs" ]
  static values = { numberOfMessages: Number }

  connect() {
    console.log("ChatApiDemoController connect")
    this.numberOfMessages = 0
    this.statusTarget.textContent = "Creating subscription"
    consumer.subscriptions.create(
      {
        channel: "ChatChannel",
      },
      {
        connected: this._connected.bind(this),
        disconnected: this._disconnected.bind(this),
        received: this._received.bind(this),
      }
    )
  }

  _connected() {
    this.statusTarget.textContent = `Connected`
  }

  _disconnected() {
    consumer.subscriptions.remove(this.subscription)
    this.statusTarget.textContent = `Disconnected`
  }

  _received(data) {
    console.log(data)
    this.numberOfMessages++
    this.statusTarget.textContent = `Received ${this.numberOfMessages} messages`
    this.logsTarget.insertAdjacentHTML('beforeend', `<li>${JSON.stringify(data)}</li>`)
  }
}
