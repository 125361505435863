import { Controller } from 'stimulus'
import { Input, Timepicker } from "mdb-ui-kit";
import { useIntersection } from 'stimulus-use'

// MDB timepicker component
// https://mdbootstrap.com/docs/standard/forms/timepicker/
// When it is initially hidden or when turbo frame inserts it, we need to
// reinitialize. We are using `.update` since hidden components are already
// initialized. So for all data-mdb-timepicker-init add this controller. For example:
//
//    <div data-mdb-timepicker-init data-controller="mdb-timepicker-appear">
export default class extends Controller {
  connect() {
    console.log("mdb-timepicker-appear connect")
    useIntersection(this)
  }

  appear(entry) {
    console.log("mdb-timepicker-appear appear")
    new Input(this.element)
    new Timepicker(this.element)
  }
}
