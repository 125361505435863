import { Controller } from 'stimulus'
import { Modal } from 'mdb-ui-kit';
// To open modal add this controller to .modal element
// to close after submit add action close on a form
//
// <div class="modal" tabindex="-1" data-controller='mdb-modal'>
// ...
// <%= f.submit "Submit", "data-action": "click->mdb-modal#close" %>

// Back button will load the modal again since <turbo-frame> contains .modal and
// src attribute (it will again load the .modal)
// so we need to remove .modal and clear src attribute
// https://turbo.hotwired.dev/handbook/building#preparing-the-page-to-be-cached
document.addEventListener("turbo:before-cache", function() {
  // remove modal since it will be opened automatically on connect
  // we need to do that by removing parent turbo-frame id='modal' which has src
  let modals = document.querySelectorAll('[data-controller="mdb-modal"]')
  modals.forEach(function(modal) {
    let parentTurboFrame = modal.closest('turbo-frame')
    if (parentTurboFrame) {
      parentTurboFrame.innerHTML = ''
      parentTurboFrame.src = null
    }
  })

  document.body.style = "";
  document.body.classList.remove('modal-open');

  let backdrop = document.querySelector('.modal-backdrop');
  if (backdrop) {
    backdrop.remove();
  }
})

export default class extends Controller {
  connect() {
    this.modal = new Modal(this.element);
    this.modal.show();
  }

  close() {
    this.modal.hide();
  }
}