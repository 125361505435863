import { Controller } from 'stimulus'
import { useMutation } from 'stimulus-use'

export default class extends Controller {
  connect() {
    useMutation(this, { childList: true })
    console.log(`activate-on-mutate#connect`)
  }

  mutate(entries) {
    // triggered when the observed element is changed
    let selector = this.data.get('selector')
    let elements = document.querySelectorAll(selector)
    elements.forEach(function(element) {
      element.disabled = false
    })
    console.log(`activate-on-mutate#mutate ${selector}`)
  }
}
