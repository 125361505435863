import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    console.log('mobile-bridge-message-handlers#initialize')
  }

  // IOS

  iosCheckout(e) {
    e.preventDefault()
    let productId = this.data.get("productId")
    console.log(`iosCheckout ${productId}`)
    window.webkit.messageHandlers.checkout.postMessage({
      productId: productId,
    })
  }

  iosManageSubscription(e) {
    e.preventDefault()
    let transactionId = this.data.get("transactionId")
    console.log(`iosManageSubscription ${transactionId}`)
    window.webkit.messageHandlers.manageSubscription.postMessage({
      transactionId: transactionId,
    })
  }

  iosRedeemCode(e) {
    e.preventDefault()
    let redeemCode = this.data.get("redeemCode")
    console.log(`iosRedeemCode ${redeemCode}`)
    window.webkit.messageHandlers.redeemCode.postMessage({
      redeemCode: redeemCode,
    })
  }

  // detail should be hash
  iosMainBell({detail}) {
    console.log(`iosMainBell ${JSON.stringify(detail)}`)
    window.webkit.messageHandlers.mainBell.postMessage(detail)
  }

  // Android

  androidCheckout(e) {
    e.preventDefault()
    let productId = this.data.get("productId")
    console.log(`androidCheckout ${productId}`)
    Android.checkout(productId)
  }

  androidManageSubscription(e) {
    e.preventDefault()
    let transactionId = this.data.get("transactionId")
    console.log(`androidManageSubscription ${transactionId}`)
    Android.manageSubscription(transactionId)
  }

  androidRedeemCode(e) {
    e.preventDefault()
    let redeemCode = this.data.get("redeemCode")
    console.log(`androidRedeemCode ${redeemCode}`)
    Android.redeemCode({
      redeemCode: redeemCode,
    })
  }

  androidMainBell({detail}) {
    console.log(`androidMainBell ${JSON.stringify(detail)}`)
    Android.mainBell(detail)
  }
}
