import jBox from 'jbox';
import 'jbox/dist/plugins/jBox.Notice.css';

function notice(message) {
  new jBox('Notice', {
    autoClose: 20000,
    attributes: {
      x: 'right',
      y: 'bottom'
    },
    stack: true,
    animation: {
      open:'bounce',
      close:'fadeOut'
    },
    content: message,
    color: 'red'
  })
  console.log(`notice("${message}"`)
}

export { notice }
