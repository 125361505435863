import { Controller } from 'stimulus'
import { PerfectScrollbar } from "mdb-ui-kit";
import { useIntersection } from 'stimulus-use'

// MDB input component https://mdbootstrap.com/docs/standard/forms/input-fields/
// When it is initially hidden or when turbo frame inserts it, we need to
// reinitialize. We are using `.update` since hidden components are already
// initialized. So for all data-mdb-scrollbar-init add this controller. For example:
//
//    <div data-mdb-input-init data-controller="mdb-scrollbar-appear">
export default class extends Controller {
  connect() {
    console.log("mdb-scrollbar-appear connect")
    useIntersection(this)
  }

  appear(entry) {
    console.log("mdb-scrollbar-appear appear")
    new PerfectScrollbar(this.element).update()
  }
}
