import { Controller } from 'stimulus'

// To open modal add this controller to .modal element
// to close after submit add action close on a form
//
// <div class="modal" tabindex="-1" data-controller='start-modal-on-connect'>
// ...
// <%= form.submit 'Update', 'data-action': 'start-modal-on-connect#close' %>

// Back button will load the modal again since <turbo-frame> contains .modal and
// src attribute (it will again load the .modal)
// so we need to remove .modal and clear src attribute
// https://turbo.hotwired.dev/handbook/building#preparing-the-page-to-be-cached
document.addEventListener("turbo:before-cache", function() {
  // remove modal since it will be opened automatically on connect
  // we need to do that by removing parent turbo-frame id='modal' which has src
  let modals = document.querySelectorAll('[data-controller="start-modal-on-connect"]')
  modals.forEach(function(modal) {
    let parentTurboFrame = modal.closest('turbo-frame')
    if (parentTurboFrame) {
      parentTurboFrame.innerHTML = ''
      parentTurboFrame.src = null
    } else {
      if (!modal.dataset.skipRemoveModalOnBeforeCache) {
        modal.remove()
      }
    }
  })
})

export default class extends Controller {
  connect() {
    $(this.element).modal()
    console.log('start-modal-on-connect')
  }

  close() {
    $(this.element).modal('hide')
    console.log('start-modal-on-connect#close')
  }
}
