import fcm from "fcm";
import {
  initMDB, Input, Tab, Collapse, Ripple, Modal, Select, Tooltip, Chip,
  Dropdown, PerfectScrollbar, Datepicker
} from "mdb-ui-kit"

console.log("page load")

document.addEventListener("turbo:load", () => {
  console.log("turbo:load 5")
  initMDB({
    Input, Tab, Collapse, Ripple, Modal, Select, Tooltip, Chip, Dropdown, Datepicker
  })

  document.querySelectorAll('[data-mdb-input-init]').forEach((mdbInput) => {
    // https://mdbootstrap.com/docs/standard/forms/input-fields/#section-dynamic-input-initialization
    new Input(mdbInput).init()
  })

  document.querySelectorAll('[data-mdb-select-init]').forEach((mdbSelect) => {
    // https://mdbootstrap.com/docs/standard/forms/input-fields/#section-dynamic-input-initialization
    //
    //new Select(mdbSelect)
  })

  if (document.querySelector("[name=current-member-profile-id]") != null) {
    $("#request-token-button").on("click", function () {
      console.log("request-token-button");
      fcm.requestToken();
    });
  }

  console.log("turbo:load 5 finished")
})
