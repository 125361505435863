import { Controller } from 'stimulus'

export default class extends Controller {
  static values = { siteKey: String, autoload: Boolean }

  connect() {
    console.log("recaptcha-v2 connect");
    window.onloadCallback = () => {
      // Google reCAPTCHA rendering code
      // https://developers.google.com/recaptcha/docs/display
      this.widgetId = grecaptcha.render("recaptcha-container", {
        sitekey: this.siteKeyValue,
        callback: this.enableButton,
        "expired-callback": this.disableButton,
      });
    };
    if (this.autoloadValue) {
      this.loadRecaptha()
    }
  }

  loadRecaptha() {
    if (this.widgetId !== undefined) {
      console.log(`recaptcha-v2 loadRecaptha skip since widgetId=${this.widgetId}`)
    } else {
      console.log("recaptcha-v2 loadRecaptha since widgetId is not present")
      var script = document.createElement('script');
      script.src = "https://www.google.com/recaptcha/api.js?onload=onloadCallback"
      document.head.appendChild(script);
    }
  }

  enableButton() {
    console.log("recaptcha-v2 enableButton");
    const button = document.getElementById("recaptcha-enabled-button");
  
 
    if (button) {
      button.removeAttribute("disabled");
    }
  }

  disableButton() {
    console.log("recaptcha-v2 disableButton");
    const button = document.getElementById("recaptcha-enabled-button");
    if (button) {
      button.setAttribute("disabled", "disabled");
    }
  }
}
