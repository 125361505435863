import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'my' ]

  initialize() {
    console.log('forms#initialize start')
  }

  // Note that you do not need submit button in the form
  // <%= f.check_box :near_me, 'data-controller': 'forms', 'data-action': 'forms#submit_on_change' %>
  // <%= f.select filter, options, { prompt: true, include_blank: "Select" }, 'data-action': 'click->forms#submit_on_change', 'data-controller': 'select2' %>
  // <%= f.select filter, options, { prompt: true, include_blank: "Select" }, 'data-action': 'change->forms#submit_on_change' %>
  submit_on_change(event) {
    let form = event.currentTarget.closest('form')
    if (form.requestSubmit) {
      form.requestSubmit()
      console.log('forms#submit_on_change form.requestSubmit()')
    } else {
      form.submit()
      console.log('forms#submit_on_change form.submit()')
    }
  }

  // <%= f.file_field :photo_id_picture, label: 'Photo ID Picture', 'data-controller': 'forms', 'data-action': 'forms#show_file_upload_name' %>
  show_file_upload_name() {
    let labelElement;
    if (this.element.nextSibling.nodeName == 'LABEL') {
      labelElement = this.element.nextSibling
    } else if (this.element.previousSibling.nodeName == 'LABEL') {
      labelElement = this.element.previousSibling
    } else {
      console.log('forms#show_file_upload_name can not find label element')
    }
    let fileName = this.element.files[0].name
    labelElement.textContent = fileName
  }

  // <%= f.button 'Upload', class: 'btn btn-primary mr-3', 'data-controller': 'forms', 'data-action': 'forms#activate_modal', type: 'button' %>
  activate_modal() {
    $('#activate-modal').modal()
  }

  activate_mdb_modal() {
    const modalElement = document.getElementById('activate-mdb-modal');
    const modal = new mdb.Modal(modalElement);
    modal.show();
  }

  // hide prefix prepend if user type +
  // <%= f.text_field :phone, prepend: "+1" %>
  hide_prepend_if_plus_is_entered(event) {
    let prependElement = event.currentTarget.previousSibling
    if (!prependElement) return
    if (!event.currentTarget.value.includes("+")) return

    prependElement.hidden = true
  }

  // clear <input name="authenticity_token" value="...">
  // and <meta name="csft-token" content="..."
  clear_authenticity_token(e) {
    e.preventDefault()
    let el = e.currentTarget.closest("form").querySelector("[name='authenticity_token']")
    el.value = 123
    document.querySelector("meta[name='csrf-token']").content="123"
    console.log(`clear_authenticity_token set the value ${el.value}`)
  }

  populate_field(event) {
    let text = event.currentTarget.getAttribute('data-forms-text')
    this.myTarget.value = text
    event.preventDefault()
  }

  disable_required(event) {
    let required_inputs = event.currentTarget.closest("form").querySelectorAll("[required='required']")
    required_inputs.forEach((required_input) => {
      required_input.required = false
    });
  }
}
