import firebase from 'firebase/app'
import 'firebase/analytics'
import 'firebase/messaging'
import { notice } from 'notice_alert'
import browser from 'browser-detect'

const firebaseConfig = {
  apiKey: "AIzaSyDAIBRY7_B4_bjHPAhb54X4g3i3wyn-J74",
  authDomain: "gofordesi.firebaseapp.com",
  projectId: "gofordesi",
  storageBucket: "gofordesi.appspot.com",
  messagingSenderId: "166002564367",
  appId: "1:166002564367:web:f734d4be328d9ad3a1327a",
};

async function addToken(token) {
  console.log(`addToken ${token}`)
  let data = {
    notification_token: {
      token: token,
      device_description: browser(),
    }
  }

  let csrf = document.querySelector("[name=csrf-token]").content
  const response = await fetch('/notification_tokens', {
    method: 'POST',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-Token': csrf,
    },
    body: JSON.stringify(data)
  })
  return response.json()
}

function requestToken() {
  // Initialize Firebase

  if (firebase.apps.length === 0) {
    firebase.initializeApp(firebaseConfig);
    firebase.analytics();
  }

  const messaging = firebase.messaging();
  messaging.requestPermission()
    .then(function () {
      console.log('requestPermission for token')
      return messaging.getToken()
    })
    .then(function (token) {
      if (document.querySelector(`[name='${token}']`) != null) {
        return { message: 'token already in db' }
      } else {
        return addToken(token)
      }
    })
    .then(function (response) {
      if (response.show) {
        notice(response.message)
      } else {
        console.log(response.message)
      }
      $('#request-token-notice').remove()
    })
    .catch(function (err) {
      console.log(err)
    })
  messaging.onMessage((payload) => {
    notice(`${payload.notification.title} ${payload.notification.body}`)
    console.log('Message received. ', payload);
  });
}

export default { requestToken }
