import { Controller } from "stimulus"

// https://www.linkedin.com/pulse/rails-6-stimulus-select2-ronak-bhatt-
require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")

import select2 from 'select2'

// <%= f.select :languages_spoken_at_home, LANGUAGES, {include_blank: false}, 'data-controller': 'select2', multiple: true %>
//
// use with tags, you can also create new tag
// <%= f.select :choose, LANGUAGES, { include_blank: true }, 'data-controller': 'select2', "data-select2-placeholder": "Select existing or create new", "data-select2-tags": true %>
//
// when you are using multiple selects with same name (for example rendering for
// each user) you have to provide different id
// <%= f.select :reject_reason, CommonString.reject_reason.map(&:text), { include_blank: true }, "data-controller": "select2", "data-select2-tags": true, "data-select2-placeholder": "Select or write a new reason", id: f.object.id  %>
export default class extends Controller {
  connect() {
    console.log("connect select2 controller")
    let placeholder = this.data.get('placeholder') || "Please select"
    // https://select2.org/tagging
    let tags = this.data.get("tags")
    $(this.element)
      .select2({
        placeholder: placeholder,
        tags: tags,
      })
      .on("change", function (e) {
        e.target.click()
      });
  }

  disconnect() {
    console.log("disconnect select2 controller")
    let el = $(this.element)
    if (el.hasClass("select2-hidden-accessible")) {
        console.log("destroying previously initialized select2")
        el.select2("destroy")
    }
  }
}
