import { Controller } from 'stimulus'
import { Input } from 'mdb-ui-kit'
import { useIntersection } from 'stimulus-use'

// MDB input component https://mdbootstrap.com/docs/standard/forms/input-fields/
// When it is initially hidden or when turbo frame inserts it, we need to
// reinitialize. We are using `.update` since hidden components are already
// initialized. So for all data-mdb-input-init add this controller. For example:
//
//    <div data-mdb-input-init data-controller="mdb-input-appear">

export default class extends Controller {
  static targets = ["profileId", "prefix"];

  connect() {
    console.log("mdb-input-appear connect")
    useIntersection(this)
  }

  appear(entry) {
    console.log("mdb-input-appear appear")
    new Input(this.element).update()
  }

 // The handleInput method modifies the input value if it starts with 'ds'.
  // Usage example in HTML:
  // <div data-mdb-input-init data-controller="mdb-input-appear">
  //   <span data-mdb-input-appear-target="prefix">DS</span>
  //   <input type="text" maxlength="8" required data-mdb-input-appear-target="profileId" data-action="mdb-input-appear#handleInput">
  // </div>

  handleInput(event) {
    const value = this.profileIdTarget.value;
    const lowerValue = value.toLowerCase();

    if (lowerValue.startsWith("ds")) {
      this.profileIdTarget.value = value.slice(2);
      this.flashPrefix();
    }
  }

  flashPrefix() {
    this.prefixTarget.classList.add('text-success');
    setTimeout(() => {
      this.prefixTarget.classList.remove('text-success');
    }, 1000);
  }
}
