import { navigator } from '@hotwired/turbo'
import { Controller } from 'stimulus'
import { useMutation } from 'stimulus-use'

// for changing url after signup step to include ?src=/registration/123 so
// reload the page will render same step
// <%= turbo_frame_tag 'registration', 'data-controller': 'turbo-frame-history' do %>
export default class extends Controller {
  connect() {
    console.log('turbo-frame-history')
    useMutation(this, { attributes: true })
  }

  mutate(entries) {
    entries.forEach((mutation) => {
      if (mutation.type == 'attributes' && mutation.attributeName == 'src') {
        const src = this.element.getAttribute("src")
        if (src) {
          const url = new URL(src)
          const path = window.location.pathname

          navigator.history.push(new URL(`${path}?src=${url.pathname}`, `${url.protocol}//${url.host}`))
        }
      }
    })
  }
}
